import React, { useState, useContext } from "react";
import {
  Menu,
  MenuItem,
  Select,
  useTheme,
  ListItemText,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SelectChangeEvent } from "@mui/material/Select";
import { useMediaQuery } from "@mui/material";
import NavBarData from "../NavBarData";
import { SelectedValueContext } from '../contexts/MenuContext';

const menuItems = NavBarData;

const NestedMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openMenuId, setOpenMenuId] = useState<number | null>(null);
  const [openSubmenuId, setOpenSubmenuId] = useState<string | null>(null);
  const [selectedValue1, setSelectedValue1] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedValue, setSelectedValue } = useContext(SelectedValueContext);
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedValue1(event.target.value);
    handleClose();
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    menuId: number
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuId(menuId);
   
  };

  const handleSubmenuClick = (
    event: React.MouseEvent<HTMLElement>,
    submenuId: string
  ) => {
    setSubmenuAnchorEl(event.currentTarget);
    setOpenSubmenuId(submenuId);
  
  };

  const handleClose = () => {
    
    setOpenMenuId(null);
    setAnchorEl(null);
    setOpenSubmenuId(null);
    setSubmenuAnchorEl(null);
  };

  const handleItemClick = (menu: any) => {
    
    setSelectedValue1(menu.text); 
    setSelectedValue(menu.link);
    navigate(menu.link);
    setOpenMenuId(null)
    handleClose();
  };

  const renderValue = (selected: string) => {
    if (!selected) {
      return <span>Select Treatment</span>;
    }
    return (
      <div style={{ display: "flex", flexWrap: "wrap", whiteSpace: "normal" }}>
        {selected}
      </div>
    );
  };

  const renderSubmenuItems = (subMenus: any[], parentMenu: any) => (
    <>
      {subMenus.map((subMenu) => (
        <div key={subMenu.text}>
          <MenuItem
            onClick={(e) =>
              parentMenu.nestedMenus && parentMenu.nestedMenus[subMenu.text]
                ? handleSubmenuClick(e, subMenu.text)
                : handleItemClick(subMenu)
            }
          >
            <ListItemText primary={subMenu.text} />
            {parentMenu.nestedMenus && parentMenu.nestedMenus[subMenu.text] && (
              <ExpandMoreIcon style={{ color: "#78AE37", maxWidth: "50px" }} />
            )}
          </MenuItem>
          {parentMenu.nestedMenus && parentMenu.nestedMenus[subMenu.text] && (
            <Menu
              anchorEl={submenuAnchorEl}
              open={openSubmenuId === subMenu.text}
              onClose={handleClose}
              MenuListProps={{
                style: {
                  backgroundColor: "#FFFFFF",
                  color: "#78AE37",
                  fontSize: "14px",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {parentMenu.nestedMenus[subMenu.text].map((nestedMenu: any) => (
                <MenuItem
                  key={nestedMenu.text}
                  onClick={() => handleItemClick(nestedMenu)}
                >
                  <ListItemText primary={nestedMenu.text} />
                </MenuItem>
              ))}
            </Menu>
          )}
        </div>
      ))}
    </>
  );

  return (
    <div>
      <Select
        labelId="select-treatment-label"
        id="select-treatment"
        value={selectedValue1}
        onChange={handleChange}
        variant="filled"
        displayEmpty
        renderValue={renderValue}
        style={{
          minWidth: isMobileSmall?"200px":"250px" ,
          marginTop: "8px",
          fontSize: "10px",
          height: "26px",
          width: "100%",      
          backgroundColor: "#78AE37",
          color: "#FFFFFF",
          paddingRight: "0px",
          borderColor: "white",
          paddingBottom: "15px",
          overflow: "hidden",
          whiteSpace: "break-spaces",
          textAlign: "center"
        }}
        sx={{
          "& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
            color: "#FFFFFF",
          },
        }}
        MenuProps={{
          MenuListProps: {
            style: {
              backgroundColor: "#FFFFFF",
              color: "#78AE37",
              fontSize: "10px",
              overflow: "hidden",
              whiteSpace: "break-spaces",
              textAlign: "center",
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }}
      >
        <MenuItem value="" disabled>
          Select Treatment
        </MenuItem>
        {menuItems.map((menu) => (
          <MenuItem key={menu.id} value={menu.text}>
            <div style={{ display: "flex" }}>
              <div onClick={() => handleItemClick(menu)}>{menu.text}</div>
              {menu.subMenus && (
                <div
                  style={{ marginTop: "2px" }}
                  onClick={(e) => {
                   
                    handleMenuClick(e, menu.id);
                    
                  }}
                >
                  <ExpandMoreIcon style={{ color: "#78AE37", maxWidth: "50px" }} />
                </div>
              )}
            </div>
          </MenuItem>
        ))}
      </Select>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          style: {
            backgroundColor: "#FFFFFF",
            color: "#78AE37",
            fontSize: "10px",
            overflow: "hidden",
            whiteSpace: "break-spaces",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        {menuItems.map((menu) => (
          <div key={menu.id}>
            {openMenuId === menu.id && renderSubmenuItems(menu.subMenus, menu)}
          </div>
        ))}
      </Menu>
    </div>
  );
};

export default NestedMenu;

import React from "react";
import { TEXT_MESSAGES } from "../constant";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

const Products: React.FC = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div 
            style={{ 
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Typography
                style={{
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    fontSize: isSmallScreen ? "32px" : "48px",
                    color: "#78AE37",
                    textAlign: "center"
                }}
            >
                {TEXT_MESSAGES.producttext}
            </Typography>
        </div>
    );
};

export default Products;

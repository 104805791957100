import React from "react";
import VideoplayerProps from "../interfaces/VideoplayerProps";

const VideoPlayer: React.FC<VideoplayerProps> = ({ videoId, width, height }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#E6F9CD",
        padding: "40px",
        marginTop:"30px",
        gap: "0px",
        opacity: "0px", marginLeft: "3%", maxWidth: "100%"
 }} >
      <iframe
        width={width || "560"}
        height={height || "345"}
        src={`https://www.youtube-nocookie.com/embed/${videoId}?si=B2CPeEZ1I0_VCDFh`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    </div>
  );
};
export default VideoPlayer;
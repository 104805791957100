import React, { createContext, useState, useEffect } from 'react';
import data from "../data";

const SelectedValueContext = createContext({
  selectedValue: null,
  setSelectedValue: (newvalue) => {},
});

const SelectedValueProvider = ({ children }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  
  return (
    <SelectedValueContext.Provider value={{ selectedValue, setSelectedValue }}>
      {children}
    </SelectedValueContext.Provider>
  );
};

export { SelectedValueContext, SelectedValueProvider };

import  React,{useContext} from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import data from "../data"; 
import { SelectedValueContext } from '../contexts/MenuContext';


interface MenuItemType {
  id: number;
  text: string;
  link: string;
  subMenus: { text: string; link: string }[];
  nestedMenus?: { [key: string]: { text: string; link: string }[] };
}

const menuItems: MenuItemType[] = [
  {
    id: 1,
    text: "Therapies",
    link: "/therapies",
    subMenus: [
      { text: "MedicalOzone", link: "/medicalozone" },
      { text: "Intravenous Anti oxidants", link: "/intravenous" },
      { text: "IV Nutrients", link: "/iv" },
      { text: "Detoxification", link: "/detox" },
      { text: "PainManagement", link: "/pain" },
      { text: "EECP", link: "/eecp" },
    ],
    nestedMenus: {
      Detoxification: [
        { text: "FIR", link: "/fir" },
        { text: "NIR", link: "/nir" },
        { text: "Red light therapy", link: "/redtherapy" },
        { text: "Korean cupping", link: "/korean" },
      ],
      PainManagement: [
        { text: "PEMF", link: "/pemf" },
        { text: "Hydrosun", link: "/hydrosun" },
        { text: "Prozone", link: "/prozone" },
        { text: "PRP", link: "/prp" },
        { text: "IMRS", link: "/imrs" },
        { text: "Acupuncture", link: "/acupuncture" },
        { text: "MarmaPoints", link: "/marma" },
        { text: "Pain portal blocks", link: "/painportal" },
        { text: "Laser therapy", link: "/laser" },
      ],
    },
  },
  {
    id: 2,
    text: "Diagnostics",
    link: "/diagnostics",
    subMenus: [
      { text: "Live Blood Analysis", link: "/liveblood" },
      { text: "Complete metabolic profile", link: "/metabolicprofle" },
      { text: "Hair Mineral analysis", link: "/mineralanalysis" },
      { text: "Gene profiling", link: "/gene" },
      { text: "Autonomic nervous system analysis", link: "/nervoussystem" },
      { text: "Alfasight thermogram", link: "/alfasight" },
      { text: "Mitochondrial function assessment", link: "/mitochondrial" },
    ],
  },
  {
    id: 3,
    text: "Integrative Cancer Health",
    link: "/cancer",
    subMenus: [
      { text: "Immunotherapy", link: "/immunotherapy" },
      { text: "SOT", link: "/sot" },
      { text: "IPT", link: "/ipt" },
      { text: "IPCT", link: "/ipct" },
      { text: "Detoxification", link: "/detoxification" },
      { text: "EFT & Music therapy", link: "/eft" },
      { text: "Rigvir", link: "/rigvir" },
      { text: "Oncotherm -soon", link: "/oncotherm" },
      { text: "Sonodynamic therapy -soon", link: "/sonodyamic" },
    ],
    nestedMenus: {
         SOT: [
        { text: "High Dose Vit C Therapy", link: "/vitc" },
        { text: "Medical Ozone", link: "/mozone" },
        { text: "GCMAF", link: "/gcmaf" },
        { text: "Anthroposophic Medicine", link: "/anthrosophic" },
        { text: "Gerson Therapy", link: "/gerson" },
      ],
  },
},
  {
    id: 4,
    text: "Integrative Mental Health",
    link: "/mentalhealth",
    subMenus: [
      { text: "Colour therapy", link: "/colourtherapy" },
      { text: "Art therapy", link: "/arttherapy" },
      { text: "Movement therapy", link: "/movement" },
      { text: "Sound therapy", link: "/sound" },
    ],
  },
];

const CustomizedMenus =()=> {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [subMenus, setSubMenus] = React.useState<string[]>([]);
  const [nestedMenus, setNestedMenus] = React.useState<{
    [key: string]: string[];
  }>({});
  const [expandedMenus, setExpandedMenus] = React.useState<{
    [key: string]: boolean;
  }>({});
  const { selectedValue, setSelectedValue } = useContext(SelectedValueContext);
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    menuItem: (typeof menuItems)[0]
  ) => {
    setAnchorEl(event.currentTarget);
    setSubMenus(menuItem.subMenus.map((subMenu) => subMenu.text));
    const nestedMenuTexts: { [key: string]: string[] } = {};
    if (menuItem.nestedMenus) {
      for (const key in menuItem.nestedMenus) {
        nestedMenuTexts[key] = menuItem.nestedMenus[key].map(
          (nestedSubMenu) => nestedSubMenu.text
        );
      }
    }
    setNestedMenus(nestedMenuTexts);
    setExpandedMenus((prevExpanded) => ({
      ...prevExpanded,
      [menuItem.text]: !prevExpanded[menuItem.text],
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenus([]);
    setNestedMenus({});
    setExpandedMenus({});
  };
  const handleItemClick = (menu: any) => {
    navigate(menu.link);
    handleClose();
  };
  const [selectedProducts, setSelectedProducts] = React.useState<any[]>([]);
  const handleItemClick1 = (submenu: string) => {
    setSelectedValue("");
    const foundSubmenu = menuItems
      .flatMap((item) => item.subMenus)
      .find((item) => item.text === submenu);
    if (foundSubmenu) {
      handleClose();
      const text= foundSubmenu.text.toLowerCase();
      setSelectedProducts(data[text]);
      setSelectedValue(foundSubmenu.link); 
     navigate(foundSubmenu.link);
    } else {
    return null; 
    }
  };
  const handleItemClick2 = (submenuText: string) => {
    const nestedSubmenus = menuItems
      .flatMap((item) =>
        item.nestedMenus ? Object.values(item.nestedMenus).flat() : []
      )
      .flat();

    const foundSubmenu = nestedSubmenus.find(
      (item) => item.text === submenuText
    );

    if (foundSubmenu) {
      const text= foundSubmenu.text.toLowerCase();
      const products= data[text];
      handleClose();
      setSelectedValue(foundSubmenu.link); 
      navigate(foundSubmenu.link);
      
    } else {
      return null;
    }
  };

  const splitSubMenus = () => {
    const withoutNested = subMenus.filter((subMenu) => !nestedMenus[subMenu]);
    const withNested = subMenus.filter((subMenu) => nestedMenus[subMenu]);
    return { withoutNested, withNested };
  };

  const { withoutNested, withNested } = splitSubMenus();

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{
          position: "absolute",
          marginTop: "-25px",
          marginLeft: "40px",
          width: "calc(50vw - 10px)",
          minWidth: "800px",
          height: "70px",
          zIndex: "999",
          backgroundColor: "rgba(120, 174, 55, 0.9)",
          borderRadius: "10px",
          padding: "0 10px",
          "@media (max-width: 800px)": {
            width: "calc(100% - 30px)",
          },
        }}
      >
        {menuItems.map((menuItem) => (
          <div key={menuItem.id}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "32px",
                width: "100%",
                textAlign: "center",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textDecoration: "none",
                color: "white",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleItemClick(menuItem)}
              >
                {menuItem.text}
              </div>
              {menuItem.subMenus.length > 0 && (
                <div onClick={(event) => handleClick(event, menuItem)}>
                  {expandedMenus[menuItem.text] ? (
                    <ExpandLessIcon
                      sx={{ marginLeft: "5px", marginTop: "8px" }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      sx={{ marginLeft: "5px", marginTop: "8px" }}
                    />
                  )}
                </div>
              )}
            </Typography>
          </div>
        ))}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: 1,
            maxWidth: "900px",
            minWidth: "300px",
            marginLeft: "10px",
          }}
        >
          {withoutNested.length > 0 && (
            <Grid item>
              <List>
                {withoutNested.map((submenu, index) => (
                  <ListItem key={index}>
                    <div
                      onClick={() => handleItemClick1(submenu)}
                      style={{
                        color: "#78AE37",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      {submenu}
                    </div>
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
         

          {withNested.length > 0 && (
            <Grid container spacing={2} sx={{ marginLeft: "10px" }}>
              {withNested.map((submenu, index) => (
                <Grid item xs={4} key={index} sx={{ whiteSpace: "nowrap" }}>
                  <List>
                    <ListItem>
                      <div
                        onClick={() => handleItemClick1(submenu)}
                        style={{
                          color: "#78AE37",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        {submenu}
                      </div>
                    </ListItem>
                    {nestedMenus[submenu] &&
                      (nestedMenus[submenu].length <= 5 ? (
                        nestedMenus[submenu].map(
                          (nestedSubMenu, nestedIndex) => (
                            <ListItem
                              key={nestedIndex}
                              sx={{ marginBottom: "4px" }}
                            >
                              <div
                                onClick={() => handleItemClick2(nestedSubMenu)}
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  marginBottom: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                {nestedSubMenu}
                              </div>
                            </ListItem>
                          )
                        )
                      ) : (
                        <Grid
                          container
                          spacing={2}
                          sx={{ marginLeft: "-10px" }}
                        >
                          {nestedMenus[submenu].map(
                            (nestedSubMenu, nestedIndex) => (
                              <Grid
                                item
                                xs={5}
                                key={nestedIndex}
                                sx={{ marginRight: "10px" }}
                              >
                                <div
                                  onClick={() =>
                                    handleItemClick2(nestedSubMenu)
                                  }
                                  style={{
                                    color: "black",
                                    textDecoration: "none",
                                    display: "block",
                                    whiteSpace: "nowrap",
                                    marginBottom: "4px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {nestedSubMenu}
                                </div>
                              </Grid>
                            )
                          )}
                        </Grid>
                      ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Menu>
      
    </Box>
    
  );
}
export default CustomizedMenus;
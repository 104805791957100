import { ABOUTUS } from "./constant";
import Therapy1 from "./assets/Therapy1.png";
import Diagnostics from './assets/Diagnostics.jpg';
import cancer from './assets/cancer.jpg';
import mentalHealth from './assets/mhealth1.jpg';
interface TherapyData {
  id: number;
  therapyname: string;
  title: string;
  description: string;
  imageUrl: string;
}

const data: { [key: string]: TherapyData[] } = {
 "/therapies" :[
    {
      id: 1,
      therapyname: "Ozone Therapy",
      title: "Knee Pain",
      description: ABOUTUS.TEXT3,
      imageUrl: Therapy1,
    },
  ],
  
     "/diagnostics" :[
    {
      id: 1,
      therapyname: "Diagnostics",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: Diagnostics,
    },
  ],
  "/cancer" :[
    {
      id: 1,
      therapyname: "Integrative Cancer Health",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: cancer,
    },
  ],
  "/mentalhealth":[
    {
      id: 1,
      therapyname: "Integrative Mental Health",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    },  
  ],
  "/medicalozone": [
    {
      id: 1,
      therapyname: "MEDICAL OZONE THERAPY",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/intravenous": [
    {
      id: 1,
      therapyname: "INTRAVENOUS ANTI OXIDANTS",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/iv": [
    {
      id: 1,
      therapyname: "IV NUTRIENTS",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/detox": [
    {
      id: 1,
      therapyname: "DETOXIFICATION",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/pain": [
    {
      id: 1,
      therapyname: "PAIN MANAGEMENT",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/eecp": [
    {
      id: 1,
      therapyname: "EECP",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/nir": [
    {
      id: 1,
      therapyname: "NIR",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/redtherapy": [
    {
      id: 1,
      therapyname: "RED LIGHT THERAPY",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/korean": [
    {
      id: 1,
      therapyname: "KOREAN CUPPING",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],

  "/fir": [
    {
      id: 2,
      therapyname: "FIR",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/pemf": [
    {
      id: 2,
      therapyname: "PEMF",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/hydrosun": [
    {
      id: 2,
      therapyname: "HYDROSUN",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/prozone": [
    {
      id: 2,
      therapyname: "PROZONE",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/prp": [
    {
      id: 2,
      therapyname: "PRP",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/imrs": [
    {
      id: 2,
      therapyname: "IMRS",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/acupuncture": [
    {
      id: 2,
      therapyname: "ACUPUNCTURE",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/marma": [
    {
      id: 2,
      therapyname: "MARMA POINTS",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/painportal": [
    {
      id: 2,
      therapyname: "PAIN PORTAL BLOCKS",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/laser": [
    {
      id: 2,
      therapyname: "LASER THERAPY",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/liveblood": [
    {
      id: 2,
      therapyname: "LIVE BLOOD ANALYSIS",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/metabolicprofle": [
    {
      id: 2,
      therapyname: "COMPLETE METABOLIC PROFILE",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/mineralanalysis": [
    {
      id: 2,
      therapyname: "HAIR MINERAL ANALYSIS",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/gene": [
    {
      id: 2,
      therapyname: "GENE PROFILING",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/nervoussystem": [
    {
      id: 2,
      therapyname: "AUTONOMIC NERVOUS SYSTEM ANALYSIS",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/alfasight": [
    {
      id: 2,
      therapyname: "ALFASIGHT THERMOGRAM",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/mitochondrial": [
    {
      id: 2,
      therapyname: "MITOCHONDRIAL FUCNTION ASSESSMENT",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/immunotherapy": [
    {
      id: 2,
      therapyname: "IMMUNOTHERAPY",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/sot": [
    {
      id: 2,
      therapyname: "SOT",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/ipt": [
    {
      id: 2,
      therapyname: "IPT",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/ipct": [
    {
      id: 2,
      therapyname: "IPCT",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/detoxification": [
    {
      id: 2,
      therapyname: "DETOXIFICATION",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/eft": [
    {
      id: 2,
      therapyname: "EFT",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/rigvir": [
    {
      id: 2,
      therapyname: "RIGVIR",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/oncotherm": [
    {
      id: 2,
      therapyname: "ONCOTHERM --soon",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/sonodyamic": [
    {
      id: 2,
      therapyname: "SONODYNAMIC THERAPY --soon",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/colourtherapy": [
    {
      id: 2,
      therapyname: "COLOUR THERAPY",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/arttherapy": [
    {
      id: 2,
      therapyname: "ART THERAPY",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/movement": [
    {
      id: 2,
      therapyname: "MOVEMENT THERAPY",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/sound": [
    {
      id: 2,
      therapyname: "SOUND THERAPY",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/vitc": [
    {
      id: 2,
      therapyname: "High Dose Vit C Therapy",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/mozone": [
    {
      id: 2,
      therapyname: "Medical Ozone",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/gcmaf": [
    {
      id: 2,
      therapyname: "GCMAF",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/anthrosophic": [
    {
      id: 2,
      therapyname: "Anthroposophic Medicine",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  "/gerson": [
    {
      id: 2,
      therapyname: "GERSON THERAPY",
      title: "Treatment Name",
      description: ABOUTUS.TEXT3,
      imageUrl: mentalHealth,
    }
  ],
  
};

export default data;

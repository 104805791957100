import React, { useContext,useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/Aboutus";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import Testimonial from "./pages/Testimonial";
import Customcard from "./components/Customcard";
import TherapiesCard from "./components/TherapiesCard";
import Diagnostics from "./pages/Diagnostics";
import Therapies from "./pages/Therapies";
import Cancer from "./pages/Cancer";
import MentalHealth from "./pages/MentalHealth";
import data from "./data";

import {
  SelectedValueContext,
  SelectedValueProvider,
} from "./contexts/MenuContext";

const App: React.FC = () => {
  const { selectedValue } = useContext(SelectedValueContext);
 
  
  return (
  
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/testimonials" element={<Testimonial />} />
          <Route path="/bookanappointment" element={<Customcard />} />
          {selectedValue !== null && (
            <Route
              path={`/${selectedValue}`}
              element={<TherapiesCard products={data[selectedValue]} />}
            />
          )}
          <Route path="/therapies" element={<Products />} />
          <Route path="/diagnostics" element={<Products />} />
          <Route path="/cancer" element={<Products />} />
          <Route path="/mentalhealth" element={<Products />} />
        </Routes>
      </Layout>
    
  );
};
export default App;

import { Typography } from '@mui/material';
import React from 'react';
import Rightsidecardprops from '../interfaces/Rightsidecardprops';
const Rightsidecard: React.FC<Rightsidecardprops> = ({ text, item }) => (
  <div>
    <Typography style={{ padding: "0 20px 10px 10px", fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto' }}>{text}</Typography>
    <Typography variant="body1" style={{
      backgroundColor: "rgba(241, 247, 235, 1)", textAlign: "left", borderRadius: "28px",
      padding: "15px 24px", marginLeft: '10px', width: "264px", height: "56px", fontFamily: 'Roboto', fontSize: '18px', fontWeight: 500, color: 'rgba(0, 0, 0, 1)'
    }}>
      {item}
    </Typography>
  </div>
);

export default Rightsidecard;
import React from 'react';
import CustomCard from '../components/PheonixCard';
import { dataArray } from '../constant';

const data = dataArray;

const Testimonial = ({ }) => {
    return (
        <div>
        <CustomCard data={data} />
        
      </div>
    );
  };
  
export default Testimonial;
import { Typography, Grid, useMediaQuery, useTheme ,styled, Box} from "@mui/material";
import React from "react";
import { TEXT_MESSAGES, ABOUTUS } from "../constant";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowProps from "../interfaces/ArrowProps"
import Sumathi from '../assets/DrSumathi-new.png';
import Tasmiya from '../assets/Dr.Tasmiya-new.png';
import Gomathi from '../assets/Dr.Gomathi-new.jpg';
import Gayathri from '../assets/Dr.Gayathri-new.png';
import Sivaranjani from '../assets/Dr.Sivaranjani-new.jpg';
import Sivasaraswathi from '../assets/Dr.Sivsaraswatih-new.jpg';
import Vijaya from '../assets/Dr.Vijaya-new.jpg';
import Arul from '../assets/Dr.Arul-new.jpg';

const NextArrow: React.FC<ArrowProps> = ({ onClick }) => (
  <Box sx={{ zIndex: 1, position: 'absolute', top: '50%', right: '-25px', transform: 'translateY(-50%)', cursor: 'pointer', }}>
    <ChevronRightIcon onClick={onClick} style={{ width: "50px", height: "50px", color: "gray" }} />
  </Box>
);
const PrevArrow: React.FC<ArrowProps> = ({ onClick }) => (
  <Box sx={{ zIndex: 1, position: 'absolute', top: '50%', left: '-25px', transform: 'translateY(-50%)', cursor: 'pointer' }}>
    <ChevronLeftIcon onClick={onClick} style={{ width: "50px", height: "50px", color: "gray" }} />
  </Box>
);
const StyledBox = styled(Box)({
  padding: '20px',
  textAlign: "center",
  display: 'flex',
  justifyContent: "center",  
  margin: '0 auto',         
  maxWidth: 'calc(100% - 40px)'
});
const StyledSlide = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: "space-evenly",
  textAlign: 'center',
  height: 'auto',
}));
const StyledImage = styled('img')({
  width: '197.86px',
  height: '189.38px',
  objectFit: 'cover',
  marginBottom: 10,
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto'
});

const items = [
  {
    name: ABOUTUS.NAME1,
    mbbs: ABOUTUS.MBBS,
    image: Vijaya,
    text: ABOUTUS.MTXT1
  },
  
  {
    name: ABOUTUS.NAME2,
    mbbs: ABOUTUS.MBBS,
    image:Tasmiya,
    text: ABOUTUS.MTXT2
  },
  {
    name: ABOUTUS.NAME3,
    mbbs: ABOUTUS.MBBS,
    image: Gomathi,
    text: ABOUTUS.MTXT3
  },
  {
    name: ABOUTUS.NAME4,
    mbbs: ABOUTUS.MBBS,
    image: Gayathri,
    text: ABOUTUS.MTXT4
  },
  {
    name: ABOUTUS.NAME5,
    mbbs: ABOUTUS.MBBS,
    image: Sivasaraswathi,
    text: ABOUTUS.MTXT5
  },
  {
    name: ABOUTUS.NAME6,
    mbbs: ABOUTUS.MBBS,
    image: Sivaranjani,
    text: ABOUTUS.MTXT6
  },
  {
    name: ABOUTUS.NAME7,
    mbbs: ABOUTUS.MBBS,
    image:Sumathi,
    text: ABOUTUS.MTXT7
  },
  
  
];
const About: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptopSmall = useMediaQuery(theme.breakpoints.between('md','lg'));
  const islargescreen = useMediaQuery(theme.breakpoints.up('xl'))
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <div
      style={{
        marginBottom: isSmallScreen ? "0px" : "20px",
        marginTop: isSmallScreen ? "50px" : "100px", textAlign: "center"
      }}
    >
      <Grid
        container
        direction= "column"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: isSmallScreen ? "30px" : "100px" }}
      >
        <Typography
          style={{
            marginBottom: isSmallScreen ? "30px" : "50px",
            fontWeight: 700,
            fontFamily: "Roboto",
            fontSize: "32px",
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          {ABOUTUS.SUCCESS}
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: isSmallScreen? "16px":"16px",
            lineHeight: isSmallScreen? "24px":"28px",
            color: "rgba(0, 0, 0, 1)",
            textAlign: "justify",
            maxWidth: isMobile ? "80%" : isSmallScreen ? "90%" : isLaptopSmall? "793px": "1294.91px",

          }}
        >
          {ABOUTUS.TEXT1}
        </Typography>
      </Grid>
     
      <div
  style={{
    display: 'flex',
    flexDirection: isSmallScreen ? 'column' : 'row',
    maxWidth: islargescreen ? "2700px" : "1531.16px",
    height: 'auto',
    gap: "20px",
    borderRadius: "10px 0px 0px 0px",
    backgroundColor: "rgba(230, 249, 205, 1)",
    marginBottom: '',
    alignItems: 'center', 
  }}
>
  <div
    style={{
      flex: 1,
      marginTop: isSmallScreen ? "30px" : "40px",
      marginLeft: isLaptopSmall ? "30px" : isSmallScreen ? "10px" : "40px",
      marginBottom: isSmallScreen ? "20px" : "60px",
    }}
  >
    <Typography
      style={{
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "32px",
        padding: "10px",
        textAlign: "left",
        marginLeft: isSmallScreen ? "30px" :  isLaptopSmall ? "90px" : "70px",
        marginBottom: isSmallScreen  ||  isLaptopSmall ? "10px" :  "30px",
      }}
    >
      {ABOUTUS.NAME} <br />
      {ABOUTUS.DESIGNATION}
    </Typography>
    <div
      style={{
        display: 'flex',
        flexDirection: isSmallScreen || isLaptopSmall ? 'column' : 'row',
        alignItems: 'center', 
        justifyContent: isSmallScreen ? 'center' : 'flex-start',
      }}
    >
      <Typography
        style={{
          textAlign: "justify",
          fontFamily: "Roboto",
          color: "#000000",
          fontSize: "14px",
          fontWeight: 400,
          marginLeft: isSmallScreen || isLaptopSmall ? "0px" : "80px",
          maxWidth: isMobile ? "80%" : isSmallScreen ? "90%": isLaptopSmall? "80%": "100%",
        }}
      >
        {ABOUTUS.TEXT2}
      </Typography>
      <img
        src={Arul}
        alt="img"
        style={{
          maxWidth: isSmallScreen ? '80%' : '90%',
          width: isSmallScreen ? "100%" :islargescreen? "35%" : "45%",
          height: isSmallScreen ? "100%":"auto",
          marginLeft: isSmallScreen ? "10px" : "40px",
          marginTop: isSmallScreen ? "10px":  isLaptopSmall ? "20px" : 0,
          marginRight: isSmallScreen || isLaptopSmall  ? "0px" : "80px",
        }}
      />
    </div>
  </div>
</div>


      <div>
        <Typography
          style={{
            marginBottom: isSmallScreen ? "20px" : "30px",
            fontWeight: 700,
            fontFamily: "Roboto",
            fontSize: "32px",
            color: "rgba(0, 0, 0, 1)",
            paddingTop: "50px",
          }}
        >
          {ABOUTUS.MEET}
        </Typography>
        <Box sx={{ padding:isSmallScreen? "20px" : '20px 50px', marginLeft: isSmallScreen? "20px": "30px", marginRight: isSmallScreen? "20px":"30px", textAlignLast: "center",outline:"none" }}>
          <Slider {...settings}>
            {items.map((member, index) => (
              <StyledSlide key={index} style={{outline:"none",border:"none"}}>
                <Typography 
                  sx={{
                    fontWeight: 500, fontSize:isLaptopSmall?"20px": "24px",
                    fontFamily: "Roboto", color: "#000000", marginRight: isSmallScreen? "": index !==1 ? "40px":"60px"
                  }}>
                  {member.name}
                </Typography>
                <Typography sx={{textAlign:"justify", marginBottom: isSmallScreen ? "" : "20px", fontWeight: 500, fontSize: "18px", fontFamily: "Roboto", color: "#000000" }}>
                  {member.mbbs}
                </Typography>
                <StyledImage
                  src={member.image}
                  alt="Profile"
                  style={{ height: "300px", width:isSmallScreen? "200px": "auto",}}

                />
                <Typography style={{ marginTop: isSmallScreen ? "" : "20px", fontFamily: "Roboto", color: "#000000",textAlign:"justify", fontWeight: 400, fontSize: "14px" }}>
                  {member.text}
                </Typography>
              </StyledSlide>
            ))}
          </Slider>
        </Box>
      </div>
    </div>
  );
};
export default About;
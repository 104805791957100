import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import {
  AppBar,
  Toolbar,
  Grid,
  Tab,
  useTheme,
  Tabs,
  Container,
  Typography,
  CssBaseline,
  Button,
  Box,
  Menu,
  MenuItem,
  Select,
  IconButton,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import newlogo from "../assets/newlogo.png";
import { TEXT_MESSAGES } from "../constant";
import PheonixButton from "./PheonixButton";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomizedMenus from "./CustomizedMenus";
import NestedMenu from "./NestedMenu";

const Navbar: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<number | null>(0);
  const isLaptopSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const pathname = location.pathname;
    const index = tabsData.findIndex((tab) => tab.to === pathname);
    setActiveTab(
      index !== -1 ? index : pathname !== "/home" || "/testimonials" || "products" || "contact-us" || "about-us" ? null : 0
    );
  }, [location.pathname]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBooking = () => {
    navigate("/bookanappointment");
  };

  const showBackgroundImage = location.pathname === "/";

  const tabsData = [
    { label: "HOME", to: "/" },
    { label: "ABOUT US", to: "/about-us" },
    { label: "PRODUCTS", to: "/products" },
    { label: "TESTIMONIALS", to: "/testimonials" },
    { label: "CONTACT US", to: "/contact-us" },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="static"
        sx={{
          backgroundSize: "contain",
          minHeight: "172px",
          marginTop: "-20px",
          backgroundColor: "rgba(120, 174, 55, 0.1)",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "auto",
          [theme.breakpoints.down("md")]: {
            minHeight: showBackgroundImage ? "280px" : "55px",
            minwidth: showBackgroundImage ? "780px" : "none",
          },
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent:"space-evenly",
            marginTop: isMobileOrTablet ? "10px" : "0px",
          }}
        >
          {isMobileOrTablet ? (
            <>
              <IconButton
                edge="start"
                aria-label="menu"
                onClick={handleMenuOpen}
                style={{ flex: "0 0 auto" }}
              >
                <MenuIcon
                  style={{ width: isMobileSmall ? "28px":"34px", height: isMobileSmall ? "52px":"58px", color: "#78AE37" }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                style={{ width: "175px" }}
              >
                {tabsData.map((tab, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setActiveTab(index);
                      handleMenuClose();
                    }}
                    component={Link}
                    to={tab.to}
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "12px",
                      color:
                        activeTab === index ? "#78AE37" : "rgba(0, 0, 0, 1)",
                      backgroundColor:
                        activeTab === index
                          ? "rgba(255, 255, 255, 0.95)"
                          : "inherit",
                    }}
                  >
                    {tab.label}
                    {activeTab === index && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          height: "2px",
                          backgroundColor: "#78AE37",
                        }}
                      />
                    )}
                  </MenuItem>
                ))}
                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                  <PheonixButton
                    variant="outlined"
                    size="large"
                    onClick={handleClick}
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "#78AE37",
                      borderColor: showBackgroundImage ? "#fff" : "#78AE37",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    {TEXT_MESSAGES.LOGIN}
                  </PheonixButton> */}
                {/* </div> */}
              </Menu>
              <img
                src={newlogo}
                alt="logo"
                style={{
                  height: isMobileSmall ? "35px": "40px",
                  width: isMobileSmall ? "62px" : "69px",
                  marginLeft: isMobileSmall?"0":"10px",
                  flex: "0 0 auto",
                }}
              />
              <div style={{display: "flex", flexDirection: isMobileSmall? "column" : "row", marginTop: isMobileSmall? "8px":"0",marginBottom : isMobileSmall? "8px":"0"}}>
              <NestedMenu />
              <PheonixButton
                variant="contained"
                size="small"
                onClick={handleBooking}
                style={{
                  color: "#fff",
                  minWidth: isMobileSmall ? "200px": "250px",         
                  height: "26px",
                  backgroundColor: "#78AE37",
                  borderColor: "#78AE37",
                  fontSize: "10px",
                  width: "100%",
                  textTransform: "none",
                  lineHeight: 1,
                  marginTop: "8px",
                  marginLeft: isMobileSmall?"0": "10px",
                }}
              >
                {TEXT_MESSAGES.APPOINTMENT}
              </PheonixButton>
              </div>
            </>
          ) : (
            <Grid
              container
              alignItems="center"
              justifyContent="space-around"
              marginTop={5}
              sx={{ flexWrap: isLaptopSmall ? "wrap" : "nowrap" }}
            >
              <Grid item>
                <img
                  src={newlogo}
                  alt="logo"
                  style={{
                    height: "120px",
                    width: "240px",
                    marginLeft: "30px",
                  }}
                />
              </Grid>
              <Grid item>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: showBackgroundImage ? "#fff" : "#78AE37",
                    },
                    "& .MuiTab-root": {
                      marginLeft: theme.spacing(1),
                      color: showBackgroundImage ? "#fff" : "#78AE37",
                      opacity: activeTab === 0 ? 1 : 0.9,
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      fontWeight: 700,
                    },
                    "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.MuiTab-textColorPrimary.Mui-selected":
                      {
                        color: showBackgroundImage ? "#fff" : "#78AE37",
                        backgroundColor: showBackgroundImage
                          ? "#78AE37"
                          : "#fff",
                      },
                    "& .css-1q2h7u5.Mui-selected": {
                      color: showBackgroundImage ? "#fff" : "#78AE37",
                      backgroundColor: showBackgroundImage ? "#78AE37" : "#fff",
                    },
                  }}
                >
                  {tabsData.map((tab, index) => (
                    <Tab
                      key={index}
                      label={tab.label}
                      value={index}
                      component={Link}
                      to={tab.to}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid
                item
                sx={{
                  marginLeft: isLaptopSmall ? "120px" : "",
                  marginTop: isLaptopSmall ? "-20px" : "",
                  marginBottom: isLaptopSmall ? "40px" : "",
                }}
              >
                <PheonixButton
                  onClick={handleBooking}
                  variant="contained"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#78AE37",
                    borderColor: "#78AE37",
                    marginRight: "20px",
                    height: "50px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: { xs: "14px", sm: "16px", md: "18px" },
                      fontWeight: 500,
                      width: "100%",
                      textAlign: "center",
                      color: "#FFFFFF",
                    }}
                  >
                    {TEXT_MESSAGES.APPOINTMENT}
                  </Typography>
                </PheonixButton>

                {/* <PheonixButton
                  variant="outlined"
                  size="large"
                  onClick={handleClick}
                  style={{
                    color: showBackgroundImage ? "#fff" : "#78AE37",
                    marginRight: "20px",
                    borderColor: showBackgroundImage ? "#fff" : "#78AE37",
                    height: "50px",
                  }}
                >
                  {TEXT_MESSAGES.LOGIN}
                </PheonixButton> */}
              </Grid>
            </Grid>
          )}
        </Toolbar>
        {!isMobileOrTablet && <CustomizedMenus />}
      </AppBar>
    </Box>
  );
};

export default Navbar;

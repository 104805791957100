
import React, { ReactNode } from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

interface LayoutProps {
    children: ReactNode; 
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div>
      <Navbar />
      <div>
      {children}
      </div>
      <Footer />
    </div>
  );
}

export default Layout;

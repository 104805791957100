import React from "react";
import {
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  useTheme,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import CustomCardProps from "../interfaces/CardProps";
import { testimonials } from "../constant";

const PheonixCard: React.FC<CustomCardProps> = ({ data }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptopL = useMediaQuery(theme.breakpoints.between("md","lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <div
      style={{
        textAlign: "center",
        paddingTop: "80px",
        paddingBottom: "40px",
        margin: !isMobileOrTablet ? "40px" : "10px",
      }}
    >
      <Typography
        component="div"
        style={{ fontWeight: 700, fontSize: "34px" }}
      >
        {testimonials.title}
      </Typography>
      <Stack spacing={1}>
        {data.map((item, index) => (
          <Grid container spacing={isLaptopL? 2 :0} key={item.name}>
            {index % 2 === 0 || isMobile ? (
              <>
                <Grid item xs={12} sm={isLaptopL?6 : 8}>
                  <Card
                    style={{
                      border: "none",
                      borderColor: "transparent",
                      boxShadow: "none",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: isMobileOrTablet
                        ? "10px"
                        : isLargeScreen
                        ? "40px"
                        : "20px",
                    }}
                  >
                    <CardContent
                      style={{
                        height: "100%",
                        border: "none",
                        borderColor: "transparent",
                      }}
                    >
                      <div>
                        {item.name.split("\n").map((line, index) => (
                          <Typography
                            key={index}
                            component="div"
                            style={{
                              textAlign: "left",
                              fontWeight: 800,
                              fontSize: isLargeScreen ? "20px" :isMobileOrTablet? "16x": isMobile? "14px":"18px",
                              color: "rgba(120, 174, 55, 1)",
                            }}
                          >
                            {line}
                          </Typography>
                        ))}

                        <Typography
                          style={{
                            textAlign: "left",
                            fontWeight: 800,
                            fontSize: isLargeScreen ? "18px" :isMobileOrTablet?  "14px" : isMobile? "12px": "20px",
                            color: "rgba(120, 174, 55, 1)",
                            marginBottom:"20px"
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: isLaptopL? "14px":isMobileOrTablet
                              ? "12px"
                              : isLargeScreen
                              ? "14px"
                              : "20px",
                            fontWeight: 400,
                            fontFamily: "Roboto",
                            textAlign: "justify",
                            lineHeight: isMobileOrTablet
                              ? "16px":'22px',
                            color: "#000000",
                          }}
                        >
                          {item.content}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={isLaptopL? 6: 4}>
                  <Card
                    style={{
                      border: "none",
                      boxShadow: "none",
                      borderColor: "transparent",
                      display: "flex",
                      height: isMobileOrTablet ? "auto" : "100%",
                      width: isLaptopL?"100%":"100%",
                    }}
                  >
                    <CardMedia
                      component="img"
                      src={item.image}
                      alt={item.name}
                      style={{
                        width:isLaptopL? "500px" : isLargeScreen
                          ? "100%"
                          : isMobileOrTablet
                          ? "100%"
                          : "500px",
                        border: "none",
                        borderColor: "transparent",
                        height:  isLaptopL? "700px":isLargeScreen
                          ? "auto"
                          : isMobileOrTablet
                          ? "auto" 
                          : "400px",
                        objectFit: "contain",
                        margin: "auto",
                        marginTop: isMobile
                          ? "auto"
                          : isMobileOrTablet
                          ? "80px"
                          : "60px",
                        marginBottom: isMobileOrTablet ? "auto" : 0,
                      }}
                    />
                  </Card>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={isLaptopL? 6 : 4}>
                  <Card
                    style={{
                      border: "none",
                      boxShadow: "none",
                      borderColor: "transparent",
                      display: "flex",
                      height: isMobileOrTablet ? "auto" : "100%",
                      width: "100%",
                    }}
                  >
                    <CardMedia
                      component="img"
                      src={item.image}
                      alt={item.name}
                      style={{
                        width: isLaptopL? "500px" : isLargeScreen
                          ? "100%"
                          : isMobileOrTablet
                          ? "100%"
                          : "400px",
                        border: "none",
                        borderColor: "transparent",
                        height:  isLaptopL? "700px" : isLargeScreen
                          ? "auto"
                          : isMobileOrTablet
                          ? "auto"
                          : "400px",
                        objectFit: "contain",
                        margin: "auto",
                        marginTop: isMobile
                          ? "auto"
                          : isMobileOrTablet
                          ? "80px"
                          : "60px",
                        marginBottom: isMobileOrTablet ? "auto" : 0,
                      }}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={isLaptopL? 6 : 8}>
                  <Card
                    style={{
                      border: "none",
                      borderColor: "transparent",
                      boxShadow: "none",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: isMobileOrTablet
                        ? "10px"
                        : isLargeScreen
                        ? "40px"
                        : "20px",
                    }}
                  >
                    <CardContent
                      style={{
                        height: "100%",
                        border: "none",
                        borderColor: "transparent",
                      }}
                    >
                      <div>
                        {item.name.split("\n").map((line, index) => (
                          <Typography
                            key={index}
                            component="div"
                            style={{
                              textAlign: "right",
                              fontWeight: 800,
                              fontSize: isLargeScreen ? "20px" :isMobileOrTablet?  "16x":  isMobile? "14px":"18px",
                              color: "rgba(120, 174, 55, 1)",
                            }}
                          >
                            {line}
                          </Typography>
                        ))}

                        <Typography
                          style={{
                            textAlign: "right",
                            fontWeight: 800,
                            fontSize: isLargeScreen ? "18px" : isMobileOrTablet? "14x": isMobile? "12px":"20px",
                            color: "rgba(120, 174, 55, 1)",
                            marginBottom:"20px"
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: isLaptopL? "14px":isMobileOrTablet
                              ? "12px"
                              : isLargeScreen
                              ? "14px"
                              : "20px",
                            fontWeight: 400,
                            fontFamily: "Roboto",
                            textAlign: "justify",
                            lineHeight: isMobileOrTablet
                              ? "16px":'22px',
                            color: "#000000",
                          }}
                        >
                          {item.content}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        ))}
      </Stack>
    </div>
  );
};

export default PheonixCard;

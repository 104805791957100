import React, { useState } from 'react';
import MuiButton from '@mui/material/Button';
import { PheonixButtonProps } from '../interfaces/PheonixButtonProps';
import { useTheme } from '@mui/material/styles';
import theme from "../theme"

const PheonixButton: React.FC<PheonixButtonProps> = ({
  variant = 'contained',
  color = 'primary',
  disabled = false,
  size = 'small',
  type,
  fullWidth ,
  onClick,
  style,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentTheme = useTheme();
  const handleClick = () => {
 onClick?.();
    setTimeout(() => setIsLoading(false), 1000);
  };
  return (
    <MuiButton
      variant={variant}
      color={color}
      disabled={disabled || isLoading}
      size={size}
      fullWidth={fullWidth}
      onClick={handleClick}
style={{
        ...style,
      }}
    >
      {isLoading ? 'Loading...' : children}
    </MuiButton>
  );
};
export default PheonixButton;
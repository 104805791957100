import React from "react";
import { Typography, Box, Stack, useTheme, Card } from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import { offices, contact_info, TEXT_MESSAGES } from "../constant";
import { makeStyles } from "@mui/styles";
import instaimage from "../assets/instagram1.webp";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import fbimage from "../assets/fb.webp";
import "../style.css";
import circleimg from "../assets/circle.webp";

import LinkedInIcon from "@mui/icons-material/LinkedIn";


const Contact = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const  isLaptoplarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isTabletview = useMediaQuery(
    "(max-width: 768px) and (max-height: 608px)"
  );
  const isLaptopSmall = useMediaQuery(
    "(max-width: 1024px) and (max-height: 857px)"
  );
  const isMobileSmall = useMediaQuery(
    "(max-width: 375px)and (max-height: 645px)"
  );
  const isMobileMedium = useMediaQuery(
    "(max-width: 325px)and (max-height: 645px)"
  );
  const useStyles = makeStyles((theme) => ({
    iframeContainer: {
      position: "relative",
      width: "100%",
      height:  isSmallScreen? "380px": isLaptoplarge? "601px":"401.91px",
      overflow: "hidden",
    },
    iframe: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height:  isLaptoplarge? "579px" :"379px",
      border: 0,
    },
  }));
  const commonStyles = {
    fontWeight: "400",
    fontSize: isMobileSmall ? "16px" : "18px",
    color: "#000000",
    fontFamily: "Roboto",
    marginRight: isMobileSmall ? "10px" : "",
  };
  const labelStyles = {
    color: "#78AE37",
    fontWeight: "700",
    fontFamily: "Roboto",
  
  };
  const classes = useStyles();

  return (
    <Box sx={{ mb: isSmallScreen ? 0 : 4 }}>
      <div className={classes.iframeContainer}>
        <iframe
          className={classes.iframe}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15547.668903611222!2d80.24407359057763!3d13.040940600906012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267ccfe529bd5%3A0x9e06692b639bcc94!2sNulife%20Wellness%20Centre%20%7C%20Ozone%20Therapy%20%7C%20EECP%20Treatment%20%7C%20PRP%20Treatment!5e0!3m2!1sen!2sin!4v1713415850241!5m2!1sen!2sin&disable=keyboard,terms,reporterror"
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      </div>
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        justifyContent={isSmallScreen ? "space-between" : "space-around"}
        sx={{
          mx: isSmallScreen ? 0 : isTablet ? 0 : 2,
          background:
            "linear-gradient(88.52deg, #78AE37 1.92%, #6CA133 58.54%)",
        }}
      >
       
        <Typography
          variant="h4"
          sx={{
            mt: "25px",
            mb: "15px",
            fontSize: isSmallScreen ? "18px" : isTablet ? "24px" : "24px",
            fontWeight: 700,
            textAlign: "center",
            justifyContent: "center",
            fontFamily: "Roboto",
            color: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {contact_info.TITLE}
        </Typography>
        <Box
          sx={{
            mx:  2,
            display: "flex",
            direction: "column",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: "space-around",
            gap: "10px",
            width: "100%",
          }}
        >
          {offices.map((office, index) => (
            <Card
              key={index}
              sx={{
                height: "auto",
                width: isSmallScreen
                  ? "auto" 
                  : isTablet
                  ? "100%"
                  : isMobileMedium
                  ? "300px"
                  : "auto",
                p:2,
                mb: 2,
                borderRadius: "10px",
                boxShadow: "0px 8px 8px 0px rgba(0, 0, 0, 0.1)",
                padding: isMobileSmall ? "5px":isSmallScreen ? "15px":isLaptoplarge ? "20px": "8px",
               
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft:"30px"
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "700" }}>
                  {office.type}
                </Typography>
                <img src={office.image1} alt="Type" />
              </div>
              <Typography
                variant="body1"
                sx={{ mt: 2, display: "flex", alignItems: "center" }}
              >
                <img src={office.image2} alt="Address Icon" />
                <strong style={labelStyles}>{contact_info.ADDRESS}</strong>
              </Typography>
              <div
                style={{
                  ...commonStyles,
                  width: isMobileSmall ? "208px" : "278px",
                  marginLeft:  "30px",
                }}
              >
                {index === 1 && !isTabletview
                  ? office.address.split("\n").map((line, i) => (
                      <React.Fragment key={i}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))
                  : index === 0 || (index === 2 && !isTabletview)
                  ? office.address.split("\n").map((line, i) => (
                      <React.Fragment key={i}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))
                  : office.address}
              </div>
              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid #ccc",
                  margin: "16px 0",
                  borderTopColor: "rgba(187, 215, 155, 1)",
                }}
              />
              <Typography
                variant="body1"
                sx={{ mt: 2, display: "flex", alignItems: "center" }}
              >
                <img
                  src={office.image5}
                  alt="Contact Person Icon"
                  style={{ marginRight: "4px" }}
                />
                <strong style={labelStyles}>
                  {contact_info.CONTACT_PERSON}
                </strong>
              </Typography>
              <div
                style={{
                  ...commonStyles,
                  marginLeft:  "30px",
                }}
              >
                {office.contactPerson}
              </div>
              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid #ccc",
                  margin: "16px 0",
                  borderTopColor: "rgba(187, 215, 155, 1)",
                }}
              />
              <Typography
                variant="body1"
                sx={{ mt: 2, display: "flex", alignItems: "center" }}
              >
                <img src={office.image3} alt="Contact Number Icon" />
                <strong style={labelStyles}>{contact_info.CONTACT}</strong>
              </Typography>
              <div
                style={{
                  ...commonStyles,
                  marginLeft:  "30px",
                }}
              >
                {office.contactNumber}
              </div>

              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid #ccc",
                  margin: "16px 0",
                  borderTopColor: "rgba(187, 215, 155, 1)",
                }}
              />
              <Typography
                variant="body1"
                sx={{ mt: 2, display: "flex", alignItems: "center" }}
              >
                <img src={office.image4} alt="Email Icon" />
                <strong style={labelStyles}>{contact_info.EMAIL}</strong>
              </Typography>
              <a
                href={TEXT_MESSAGES.maillink}
                className="footer-link"
                style={{
                  ...commonStyles,
                  marginLeft:  "30px",
                  fontSize: isMobileSmall ? "14px" : "16px",
                  marginRight: "10px",
                }}
              >
                {office.email}
              </a>
              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid #ccc",
                  margin: "16px 0",
                  borderTopColor: "rgba(187, 215, 155, 1)",
                }}
              />
              <Typography
                variant="body1"
                sx={{ mt: 2, display: "flex", alignItems: "center" }}
              >
                <AccessTimeIcon style={{color:"#78AE37"}}/>
                <strong style={labelStyles}>{contact_info.WORK}</strong>
              </Typography>
              <div
                style={{
                  ...commonStyles,
                  marginLeft:  "30px",
                                }}
              >
                {office.workingHours}
              </div>
            </Card>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};
export default Contact;
